import React from 'react';

interface CliveConfigurationOptionsValue {
  showCliveConfigOptions: boolean;
}

const CliveConfigurationOptionsContext = React.createContext<CliveConfigurationOptionsValue>({
  showCliveConfigOptions: false,
});

export const CLIVE_ADDITIONAL_INSTRUCTIONS_FIELD_KEY = 'clive_additional_instructions';

export const CliveConfigurationOptionsContextProvider: React.FC = ({ children }) => {
  return (
    <CliveConfigurationOptionsContext.Provider
      value={{
        showCliveConfigOptions: true,
      }}
    >
      {children}
    </CliveConfigurationOptionsContext.Provider>
  );
};

export const useCliveConfigOptions = (): CliveConfigurationOptionsValue => {
  return React.useContext(CliveConfigurationOptionsContext);
};
