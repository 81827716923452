import React from 'react';
import axios from 'axios';
import type { FormikHelpers, FormikProps, FormikValues } from 'formik';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import ButtonsContainer from '~/components/core/Atomic/Buttons/ButtonsContainer';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { SubReservesFormik } from '~/components/exposures/SubReservesFormik';
import useOrganization from '~/components/OrganizationContext';
import type {
  StatReserveConfiguration,
  StatSubReserveConfig,
} from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationPage';
import type { StatReserveConfigurationUpsertData } from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationSubRulesTable';
import StatReserveUpsertDialogConditionFormik from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveUpsertDialogConditionFormik';
import { MonetaryValueTextFieldFormik } from '~/components/TextFieldFormik';
import { containsOneCountryWithStates, reportAxiosError } from '~/Utils';

const FIELD_IDS = {
  ID: 'id',
  EXPENSES_RESERVE: 'expenses_reserve_amount',
  EXPENSES_SUB_RESERVES: 'expenses_sub_reserves_amounts',
  INDEMNITY_RESERVE: 'indemnity_reserve_amount',
  INDEMNITY_SUB_RESERVES: 'indemnity_sub_reserves_amounts',
  COUNTRIES: 'countries',
  ALL_COUNTRIES: 'is_all_countries',
  STATES: 'states',
  ALL_STATES: 'all_states',
  ALL_SUB_ORGS: 'all_sub_organization_ids',
  SUB_ORGS: 'sub_organization_ids',
} as const;
interface StatSubReservesFormikValues {
  id?: number;
  indemnity_reserve_amount: number;
  expenses_reserve_amount: number;
  indemnity_sub_reserves_amounts?: Record<string, number>;
  expenses_sub_reserves_amounts?: Record<string, number>;
  countries?: string[];
  states?: string[];
  sub_organization_ids?: number[];
  is_all_countries?: boolean;
  all_states?: string[];
  all_sub_organization_ids?: number[];
}

export interface StatReserveUpsertDialogProps {
  statReserveConfig: StatReserveConfigurationUpsertData;
  onSubmit: () => void;
  onClose: () => void;
  shouldDisplayCountries?: boolean;
  shouldDisplayStates?: boolean;
  shouldDisplaySubOrgs?: boolean;
  specificSubOrgIdOptions?: number[];
}
const StatReserveUpsertDialog: React.FC<StatReserveUpsertDialogProps> = ({
  statReserveConfig,
  onSubmit,
  onClose,
  shouldDisplayCountries,
  shouldDisplayStates,
  shouldDisplaySubOrgs,
  specificSubOrgIdOptions,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationId } = useOrganization();

  const getSerializedStatReserveConfig = ({
    statReserveConfig,
    formikSubReservesValues,
  }: {
    statReserveConfig: StatReserveConfigurationUpsertData;
    formikSubReservesValues: StatSubReservesFormikValues;
  }) => {
    const config: StatReserveConfiguration = {
      ...statReserveConfig,
      indemnity_reserve_amount: formikSubReservesValues.indemnity_reserve_amount,
      expenses_reserve_amount: formikSubReservesValues.expenses_reserve_amount,
      sub_rules: undefined,
    };
    if (formikSubReservesValues.expenses_sub_reserves_amounts && statReserveConfig.expenses_sub_reserves_amounts) {
      config.expenses_sub_reserves_amounts = {};
      for (const key in formikSubReservesValues.expenses_sub_reserves_amounts) {
        config.expenses_sub_reserves_amounts[key] = {
          amount: formikSubReservesValues.expenses_sub_reserves_amounts[key],
          display_name: statReserveConfig.expenses_sub_reserves_amounts[key].display_name,
        };
      }
    }

    if (formikSubReservesValues.indemnity_sub_reserves_amounts && statReserveConfig.indemnity_sub_reserves_amounts) {
      config.indemnity_sub_reserves_amounts = {};
      for (const key in formikSubReservesValues.indemnity_sub_reserves_amounts) {
        config.indemnity_sub_reserves_amounts[key] = {
          amount: formikSubReservesValues.indemnity_sub_reserves_amounts[key],
          display_name: statReserveConfig.indemnity_sub_reserves_amounts[key].display_name,
        };
      }
    }
    config.is_all_countries = formikSubReservesValues.is_all_countries;
    config.countries = formikSubReservesValues.countries;
    if (formikSubReservesValues.is_all_countries || !containsOneCountryWithStates(formikSubReservesValues.countries)) {
      config.states = undefined;
      formikSubReservesValues.states = undefined;
    } else if (formikSubReservesValues.states) {
      config.states = formikSubReservesValues.states;
    }
    if (formikSubReservesValues.sub_organization_ids) {
      config.sub_organization_ids = formikSubReservesValues.sub_organization_ids;
    }
    if (statReserveConfig.isNewRule) {
      config.id = undefined;
      config.is_sub_rule = true;
    }
    return config;
  };
  const handleSubmit = async (
    values: StatSubReservesFormikValues,
    formikHelpers: FormikHelpers<StatSubReservesFormikValues>
  ) => {
    try {
      const isInsertRow = statReserveConfig.isNewRule || !statReserveConfig.id;
      if (!isInsertRow) {
        await axios.patch(
          `/api/v1/organizations/${organizationId}/stat_reserve_configuration/${statReserveConfig.id}`,
          getSerializedStatReserveConfig({ statReserveConfig, formikSubReservesValues: values })
        );
      } else {
        await axios.post(
          `/api/v1/organizations/${organizationId}/stat_reserve_configuration`,
          getSerializedStatReserveConfig({ statReserveConfig, formikSubReservesValues: values })
        );
      }
      onSubmit();
    } catch (error) {
      formikHelpers.setSubmitting(false);
      await reportAxiosError(error);
    }
  };

  const initialIndemnitySubReservesAmounts: Record<string, number> = {};

  if (statReserveConfig.indemnity_sub_reserves_amounts) {
    Object.entries(statReserveConfig.indemnity_sub_reserves_amounts).forEach(([key, subReserve]) => {
      initialIndemnitySubReservesAmounts[key] = statReserveConfig.isNewRule ? 0 : subReserve.amount;
    });
  }

  const expensesIndemnitySubReservesAmounts: Record<string, number> = {};

  if (statReserveConfig.expenses_sub_reserves_amounts) {
    Object.entries(statReserveConfig.expenses_sub_reserves_amounts).forEach(([key, subReserve]) => {
      expensesIndemnitySubReservesAmounts[key] = statReserveConfig.isNewRule ? 0 : subReserve.amount;
    });
  }

  const initialValues: StatSubReservesFormikValues = {
    [FIELD_IDS.ID]: statReserveConfig.id,
    [FIELD_IDS.INDEMNITY_RESERVE]: statReserveConfig.isNewRule ? 0 : statReserveConfig.indemnity_reserve_amount,
    [FIELD_IDS.EXPENSES_RESERVE]: statReserveConfig.isNewRule ? 0 : statReserveConfig.expenses_reserve_amount,
    [FIELD_IDS.INDEMNITY_SUB_RESERVES]: initialIndemnitySubReservesAmounts,
    [FIELD_IDS.EXPENSES_SUB_RESERVES]: expensesIndemnitySubReservesAmounts,
    [FIELD_IDS.COUNTRIES]: statReserveConfig.countries ? statReserveConfig.countries : [],
    [FIELD_IDS.STATES]: statReserveConfig.states ? statReserveConfig.states : [],
    [FIELD_IDS.SUB_ORGS]: statReserveConfig.sub_organization_ids ? statReserveConfig.sub_organization_ids : [],
    [FIELD_IDS.ALL_COUNTRIES]: statReserveConfig.is_all_countries,
    [FIELD_IDS.ALL_STATES]:
      statReserveConfig.states &&
      statReserveConfig.states.length === 0 &&
      containsOneCountryWithStates(statReserveConfig.countries)
        ? []
        : undefined,
    [FIELD_IDS.ALL_SUB_ORGS]:
      statReserveConfig.sub_organization_ids && statReserveConfig.sub_organization_ids.length === 0 ? [] : undefined,
  };

  let requiredMessage =
    shouldDisplayStates && shouldDisplaySubOrgs && !shouldDisplayCountries
      ? `Must select at least one State or Sub-Organization`
      : 'Required';
  if (shouldDisplayCountries && shouldDisplaySubOrgs) {
    if (specificSubOrgIdOptions && specificSubOrgIdOptions.length === 1) {
      requiredMessage = `Must select a Country`;
    } else {
      requiredMessage = `Must select a Country, or at least one Sub-Organization`;
    }
  }
  const validationSchema = Yup.object().shape({
    [FIELD_IDS.STATES]: Yup.array().test('states-required-if-sub-orgs-empty', requiredMessage, (value, context) => {
      const subOrgs: [] = context.parent[FIELD_IDS.SUB_ORGS];
      // (Countries or States) and Sub-Orgs must have at least one selected
      return (
        (subOrgs && subOrgs.length > 0) ||
        (value && value.length > 0) ||
        !(statReserveConfig.isNewRule || statReserveConfig.is_sub_rule) ||
        shouldDisplayCountries === true
      );
    }),
    [FIELD_IDS.SUB_ORGS]: Yup.array().test('sub-orgs-required-if-states-empty', requiredMessage, (value, context) => {
      const states: [] = context.parent[FIELD_IDS.STATES];
      const countries: [] = context.parent[FIELD_IDS.COUNTRIES];
      const isAllCountries: boolean = context.parent[FIELD_IDS.ALL_COUNTRIES];
      // (Countries or States) and Sub-Orgs must have at least one selected
      return (
        (states && states.length > 0) ||
        (value && value.length > 0) ||
        !(statReserveConfig.isNewRule || statReserveConfig.is_sub_rule) ||
        (shouldDisplayCountries === true && ((countries && countries.length > 0) || isAllCountries))
      );
    }),
    [FIELD_IDS.COUNTRIES]: Yup.array().test('country-required', requiredMessage, (value, context) => {
      const subOrgs: [] = context.parent[FIELD_IDS.SUB_ORGS];
      const isAllCountries: boolean = context.parent[FIELD_IDS.ALL_COUNTRIES];
      // (Countries or States) and Sub-Orgs must have at least one selected
      return (
        (subOrgs && subOrgs.length > 0 && !(specificSubOrgIdOptions && specificSubOrgIdOptions.length === 1)) || // if the coverage is specific to 1 sub org, then country is required
        (shouldDisplayCountries === true && ((value && value.length > 0) || isAllCountries)) ||
        shouldDisplayCountries === false ||
        !(statReserveConfig.isNewRule || statReserveConfig.is_sub_rule)
      );
    }),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {(formikProps: FormikProps<FormikValues>) => {
        const { handleSubmit, isSubmitting } = formikProps;

        return (
          <div>
            <CardDialog
              isDialog
              title={`Add Rule (${statReserveConfig.coverage_display_name})`}
              onClose={onClose}
              maxWidth="sm"
              fullWidth
            >
              <div className="grid grid-cols-1 gap-32">
                {shouldDisplayCountries || shouldDisplayStates || shouldDisplaySubOrgs ? (
                  <StatReserveUpsertDialogConditionFormik
                    shouldDisplayCountries={shouldDisplayCountries}
                    shouldDisplayStates={shouldDisplayStates}
                    shouldDisplaySubOrgs={shouldDisplaySubOrgs}
                    countryFieldId={FIELD_IDS.COUNTRIES}
                    allCountriesFieldId={FIELD_IDS.ALL_COUNTRIES}
                    statesFieldId={FIELD_IDS.STATES}
                    allStatesFieldId={FIELD_IDS.ALL_STATES}
                    subOrgsFieldId={FIELD_IDS.SUB_ORGS}
                    allSubOrgsFieldId={FIELD_IDS.ALL_SUB_ORGS}
                    specificSubOrgIdOptions={specificSubOrgIdOptions}
                  />
                ) : null}
                <div className="grid grid-cols-1 gap-24">
                  <div>
                    <h4>Expenses Reserve</h4>
                    <div>
                      <StatReserveSubReservesFormik
                        subReservesFieldId={FIELD_IDS.EXPENSES_SUB_RESERVES}
                        totalFieldId={FIELD_IDS.EXPENSES_RESERVE}
                        isSubReservesConfigEnabled={statReserveConfig.is_expenses_sub_reserves_config_enabled}
                        subReservesAmountsDict={statReserveConfig.expenses_sub_reserves_amounts}
                        fieldLabel="Expenses"
                      />
                    </div>
                  </div>
                  <div>
                    <h4>Indemnity Reserve</h4>
                    <div>
                      <StatReserveSubReservesFormik
                        subReservesFieldId={FIELD_IDS.INDEMNITY_SUB_RESERVES}
                        totalFieldId={FIELD_IDS.INDEMNITY_RESERVE}
                        isSubReservesConfigEnabled={statReserveConfig.coverage_is_indemnity_sub_reserves_config_enabled}
                        subReservesAmountsDict={statReserveConfig.indemnity_sub_reserves_amounts}
                        fieldLabel="Indemnity"
                      />
                    </div>
                  </div>
                </div>
                <ButtonsContainer className="mt-40">
                  <CancelButton disabled={isSubmitting} onClick={onClose} />
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                    Save
                  </Button>
                </ButtonsContainer>
              </div>
            </CardDialog>
          </div>
        );
      }}
    </Formik>
  );
};

interface StatReservesSubReservesFormikProps {
  isSubReservesConfigEnabled: boolean;
  subReservesAmountsDict: Record<string, StatSubReserveConfig> | undefined;
  subReservesFieldId: string;
  totalFieldId: string;
  fieldLabel: string;
}

const StatReserveSubReservesFormik: React.FC<StatReservesSubReservesFormikProps> = ({
  isSubReservesConfigEnabled,
  subReservesAmountsDict,
  subReservesFieldId,
  totalFieldId,
  fieldLabel,
}) => {
  return (
    <div>
      {isSubReservesConfigEnabled && subReservesAmountsDict ? (
        <SubReservesFormik
          subReservesFieldId={subReservesFieldId}
          totalFieldId={totalFieldId}
          totalFieldLabel={`Total ${fieldLabel} Amount`}
          subReservesConfigMap={new Map<string, StatSubReserveConfig>(Object.entries(subReservesAmountsDict))}
          displayNameKey="display_name"
        />
      ) : (
        <div className="grid">
          <MonetaryValueTextFieldFormik id={totalFieldId} label={`${fieldLabel} Amount`} allowNegative={false} />
        </div>
      )}
    </div>
  );
};

export default StatReserveUpsertDialog;
