import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get, isEmpty, snakeCase } from 'lodash';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import InnerCard from '~/components/core/Cards/InnerCard';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import {
  CLIVE_ADDITIONAL_INSTRUCTIONS_FIELD_KEY,
  useCliveConfigOptions,
} from '~/components/SystemConfiguration/CliveConfiguration/CliveConfigurationContext';
import TextFieldFormik, { useSetDefaultFieldsOnChange } from '~/components/TextFieldFormik';

import HiddenIndication from '../../HiddenIndication/HiddenIndication';
import ArrayFieldFormik from '../../OptionsCard/NestedFieldFormik/ArrayFieldFormik';
import SwitchOrDelete from '../../OptionsCard/SwitchOrDelete';

const getActions = ({ isActive = false, actions = undefined }) => (
  <div>
    <HiddenIndication isActive={isActive} />
    {actions}
  </div>
);

const SelectCategory = ({ id }) => {
  const { values } = useFormikContext();
  const categories = get(values, 'configuration.categories', []);

  return (
    <TextFieldFormik id={id} label="Category" select fullWidth disabled={!categories.length} clearable>
      {categories.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.desc}
        </MenuItem>
      ))}
    </TextFieldFormik>
  );
};

SelectCategory.propTypes = {
  id: PropTypes.string,
};

const NESTED_FIELD_CONFIG = [
  { id: 'desc', label: 'Display name', type: 'text' },
  { id: 'id', label: 'Key', type: 'text', disableEditAfterInitialSet: true },
  {
    id: 'category',
    label: 'Category',
    type: 'custom',
    customComponent: SelectCategory,
  },
  {
    id: 'active',
    label: '',
    type: 'custom',
    customComponent: SwitchOrDelete,
  },
];

const NESTED_FIELD_CONFIG_WITH_CLIVE = [
  ...NESTED_FIELD_CONFIG,
  {
    id: CLIVE_ADDITIONAL_INSTRUCTIONS_FIELD_KEY,
    label: 'Clive identification instructions',
    type: 'text',
    additionalProps: { fullWidth: true },
  },
];

const INNER_FIELD_INITIAL_VALUES = {
  id: '',
  desc: '',
  category: undefined,
  active: true,
  [CLIVE_ADDITIONAL_INSTRUCTIONS_FIELD_KEY]: '',
};

const IncidentTypeConfigurationCard = ({
  idPrefix,
  fieldId,
  forceRequired = false,
  disabledCollapse = false,
  actions = undefined,
}) => {
  const classes = useStyles();
  const { values, setFieldValue, initialValues, isSubmitting, touched } = useFormikContext();
  const id = idPrefix ? `${idPrefix}.${fieldId}` : fieldId;
  const field = get(values, id, {});
  const wasKeyTouched = get(touched, `${id}.id`);

  const isNewField = isEmpty(get(initialValues, id, {}));
  const isActive = get(values, `${id}.active`, false);

  const { showCliveConfigOptions } = useCliveConfigOptions();

  useSetDefaultFieldsOnChange(
    field.desc,
    {
      [`${id}.id`]: snakeCase(field.desc),
    },
    null,
    !isNewField && !wasKeyTouched
  );

  // Set default value for active to true - only on first render
  useEffect(() => {
    if (field?.active !== false) {
      setFieldValue(`${id}.active`, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set default value for sub_types active to true - only on first render
  useEffect(() => {
    const subTypes = get(values, `${id}.sub_types`, []);
    subTypes.forEach((option, index) => {
      if (option?.active !== false) {
        setFieldValue(`${id}.sub_types.${index}.active`, true);
      }
    });
    if (isNewField && isEmpty(subTypes)) {
      setFieldValue(`${id}.sub_types`, [INNER_FIELD_INITIAL_VALUES]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nestedFieldsConfig = useMemo(
    () => (showCliveConfigOptions ? NESTED_FIELD_CONFIG_WITH_CLIVE : NESTED_FIELD_CONFIG),
    [showCliveConfigOptions]
  );

  return (
    <Grid item xs={12}>
      <CollapsibleWrapper
        title={field.desc}
        subtitle={`Key: ${field.id}`}
        backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.LIST}
        actionCard
        noBorderLine
        noDivider
        actions={getActions({ isActive, actions })}
        disabled={disabledCollapse}
        defaultState={isNewField}
      >
        <InnerCard>
          <Grid container className="flex items-center" spacing={3}>
            <Grid item xs={6}>
              <TextFieldFormik id={`${id}.desc`} label="Incident Type" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormik id={`${id}.id`} label="Key" fullWidth disabled={!isNewField} />
            </Grid>
            {showCliveConfigOptions ? (
              <Grid item xs={12}>
                <TextFieldFormik
                  id={`${id}.${CLIVE_ADDITIONAL_INSTRUCTIONS_FIELD_KEY}`}
                  label="Clive identification instructions"
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
            ) : null}
            <Grid item xs={2}>
              <SwitchFormik
                id={`${id}.active`}
                label="Show"
                className={classes.formsSwitch}
                hideErrorGap
                disabled={forceRequired}
              />
            </Grid>
            <Grid item xs={12}>
              <CardDialog noCardTitle contentStyle={{ padding: '16px' }}>
                <ArrayFieldFormik
                  fieldId={`${id}.sub_types`}
                  label="Sub-Types"
                  innerObjectConfig={nestedFieldsConfig}
                  disabled={isSubmitting}
                  initialNewItemValues={INNER_FIELD_INITIAL_VALUES}
                  addBtnLabel="ADD SUBTYPE"
                  columnSize={8}
                />
              </CardDialog>
            </Grid>
          </Grid>
        </InnerCard>
      </CollapsibleWrapper>
    </Grid>
  );
};

IncidentTypeConfigurationCard.propTypes = {
  idPrefix: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  savedValues: PropTypes.object,
  forceRequired: PropTypes.bool,
  isConfiguredField: PropTypes.bool,
  disabledCollapse: PropTypes.bool,
  actions: PropTypes.node,
};

export default IncidentTypeConfigurationCard;
