import React from 'react';

import CardDialog from '~/components/CardDialog';
import Account from '~/components/SystemConfiguration/CliveConfiguration/Account';
import { CliveFnolConfigurationTab } from '~/components/SystemConfiguration/CliveConfiguration/Fnol/FnolConfigurationTab';
import SubOrganizationConfiguration from '~/components/SystemConfiguration/CliveConfiguration/SubOrganizationConfigurationTable';
import ConfigurationScreenWithTabs from '~/components/SystemConfiguration/ConfigurationScreenWithTabs';
import OperationsBreadcrumbs from '~/components/SystemConfiguration/OperationsBreadcrumbs';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import type { OrganizationModel } from '~/components/types/organization-types';
import { ADMIN_ROUTES } from '~/routesConstants';

const CONFIGURATION_SUBTITLE = 'Set up clive configuration.';

const CliveConfiguration: React.FC = () => {
  const { organization } = useSysconfig() as { organization: OrganizationModel };

  const tabs = [
    {
      label: 'Account',
      url: ADMIN_ROUTES.CLIVE_CONFIGURATION.ACCOUNT,
      component: Account,
      isEnabled: true,
    },
    {
      label: 'Sub Organizations',
      url: ADMIN_ROUTES.CLIVE_CONFIGURATION.SUB_ORGANIZATION,
      component: SubOrganizationConfiguration,
      isEnabled: organization.sub_organizations_enabled,
    },
    {
      label: 'FNOL Configuration',
      url: ADMIN_ROUTES.CLIVE_CONFIGURATION.FNOL,
      component: CliveFnolConfigurationTab,
      isEnabled: organization.sub_organizations_enabled,
    },
  ];

  return (
    <CardDialog noCardTitle>
      <OperationsBreadcrumbs currentTab="Clive Configuration" />
      <ConfigurationScreenWithTabs
        tabs={tabs}
        title="Clive CONFIGURATION"
        subtitle={CONFIGURATION_SUBTITLE}
        operationTabName="Clive Configuration"
      />
    </CardDialog>
  );
};

export default CliveConfiguration;
