import React, { useState } from 'react';

import CardDialog from '~/components/CardDialog';
import { Heading } from '~/components/core';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import AddDocumentTypeButton from '~/components/SystemConfiguration/DocumentTypes/AddDocumentTypeButton';
import DocumentTypesFilter from '~/components/SystemConfiguration/DocumentTypes/DocumentTypesFilter';
import DocumentTypesTable from '~/components/SystemConfiguration/DocumentTypes/DocumentTypesTable';
import OperationsBreadcrumbs from '~/components/SystemConfiguration/OperationsBreadcrumbs';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';

const DocumentTypesConfigurationScreen: React.FC = () => {
  const [typesSearchTerm, setTypesSearchTerm] = useState('');
  const [showOnlyCustomTypes, setShowOnlyCustomTypes] = useState(false);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();

  const {
    isLoading,
    isError,
    data: documentTypesDict = {},
    reloadData: reloadDocumentTypes,
  } = useDataFetcher(`/api/v1/document_types/organizations/${organization.id}`);

  const displayLoading = isLoading || isError;

  return (
    <CardDialog noCardTitle>
      <OperationsBreadcrumbs currentTab="Document Types" />
      <div className="grid grid-cols-2 items-center">
        <Heading className="mt-20">Document Types</Heading>
        <AddDocumentTypeButton documentTypesDict={documentTypesDict} reloadDocumentTypes={reloadDocumentTypes} />
      </div>
      <DocumentTypesFilter
        onSearchTermChanged={(term) => setTypesSearchTerm(term)}
        onShowOnlyCustomTypesChanged={(showOnly) => setShowOnlyCustomTypes(showOnly)}
      />
      {displayLoading ? (
        <SkeletonTable rowsCount={10} columnsCount={10} isError={isError} />
      ) : (
        <DocumentTypesTable
          documentTypesDict={documentTypesDict}
          reloadDocumentTypes={reloadDocumentTypes}
          searchTerm={typesSearchTerm}
          showOnlyCustomTypes={showOnlyCustomTypes}
        />
      )}
    </CardDialog>
  );
};

export default DocumentTypesConfigurationScreen;
