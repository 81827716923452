import React, { useState } from 'react';
import axios from 'axios';

import { useStyles } from '~/assets/styles';
import { Heading } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import type { OrganizationModel } from '~/components/types/organization-types';
import useDataFetcher from '~/components/useDataFetcher';
import { reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

import { useSysconfig } from '../SystemConfigurationScreen';

const Account: React.FC = () => {
  const { organization } = useSysconfig() as { organization: OrganizationModel };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const baseConfigsRoute = `/api/v1/organizations/${organization?.id}/ai_configuration`;

  const { isLoading, isError, data: account, reloadData: reloadAccount } = useDataFetcher(baseConfigsRoute);
  const classes = useStyles();

  const handleCreateAccount = async (): Promise<void> => {
    try {
      setIsSubmitting(true);
      await axios.post(`${baseConfigsRoute}/create_account`);
      await reloadAccount();
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-[50vh] bg-white">
      <LoadingSwitch isLoading={isLoading} isError={isError}>
        <div className="h-full w-full p-40">
          {account?.account_id ? (
            <Heading variant={Heading.TYPES.H3}>Clive Account: {account?.account_id}</Heading>
          ) : (
            <Button
              variant="contained"
              className={cn(classes.cancelButton)}
              onClick={handleCreateAccount}
              disabled={isSubmitting}
            >
              Create Clive Account
            </Button>
          )}
        </div>
      </LoadingSwitch>
    </div>
  );
};
export default Account;
