import React from 'react';
import { getIn, useFormikContext } from 'formik';

import { ErrorHelperTextFormik } from '~/components/core/Formik/ErrorHelperTextFormik';
import useFormikChangeListener from '~/components/core/Formik/FormikChangeListener';
import MultiSelectAutoComplete from '~/components/core/Molecules/Fields/MultiSelectAutoComplete';
import useGlobalAddresses from '~/components/hooks/useGlobalAddresses';
import { COUNTRIES_DICT } from '~/Types';

export interface CountryMultiSelectFormikProps {
  id: string;
  label?: string;
  onChange?: (value?: (keyof typeof COUNTRIES_DICT)[] | null) => void;
  disabled?: boolean;
  className?: string;
  statesFieldIdToResetOnChange?: string;
  allSelectedFieldId?: string;
  limitTags?: number;
}

const ALL_ITEM_VALUE = '__all__';
const getOptionLabel = (option: string): string => {
  if (option === ALL_ITEM_VALUE) {
    return 'All';
  }
  if (option in COUNTRIES_DICT) {
    return COUNTRIES_DICT[option as keyof typeof COUNTRIES_DICT];
  }
  return 'N/A';
};

const sortedCountriesOptions = (countryOptions: string[]) => {
  return [...countryOptions].sort((option1, option2) => getOptionLabel(option1).localeCompare(getOptionLabel(option2)));
};

const CountryMultiSelectFormik = ({
  id,
  label = 'Countries',
  disabled = false,
  className,
  statesFieldIdToResetOnChange,
  allSelectedFieldId,
  limitTags,
}: CountryMultiSelectFormikProps): JSX.Element => {
  const { countriesOptions } = useGlobalAddresses({ countriesConfigurationKey: 'contact_location' }) as {
    countriesOptions: string[];
  };
  const { values, setFieldValue } = useFormikContext<Record<string, string | string[] | boolean>>();
  const sortedCountries = sortedCountriesOptions(countriesOptions);
  useFormikChangeListener({
    listenForKeys: id,
    onChange: () => {
      if (!statesFieldIdToResetOnChange) {
        return;
      }
      setFieldValue(statesFieldIdToResetOnChange, []);
    },
    runOnFirstRender: false,
  });

  const setAllSelectedFieldValue = (value: boolean) => {
    if (allSelectedFieldId) {
      setFieldValue(allSelectedFieldId, value);
    }
  };

  const isAllCountriesFormikValue = allSelectedFieldId && getIn(values, allSelectedFieldId) === true;
  const formikCountries = getIn(values, id);
  const selectedCountries = formikCountries ? [...formikCountries] : [];
  if (allSelectedFieldId) {
    if (!sortedCountries.includes(ALL_ITEM_VALUE)) {
      sortedCountries.unshift(ALL_ITEM_VALUE);
    }
    if (isAllCountriesFormikValue && !selectedCountries.includes(ALL_ITEM_VALUE)) {
      selectedCountries.push(ALL_ITEM_VALUE);
    }
  }

  const handleSelectionChange = (value: string[] | null | undefined) => {
    const isAllInSelectedItems = value?.includes(ALL_ITEM_VALUE) === true;

    if (isAllInSelectedItems) {
      if (!isAllCountriesFormikValue) {
        setAllSelectedFieldValue(true);
        setFieldValue(id, []);
      } else if (value?.length > 1) {
        const filteredValue = value.filter((item) => item !== ALL_ITEM_VALUE);
        setAllSelectedFieldValue(false);
        setFieldValue(id, filteredValue);
      }
    } else {
      setAllSelectedFieldValue(false);
      setFieldValue(id, value);
    }
  };

  return (
    <>
      <MultiSelectAutoComplete
        id={id}
        label={label}
        options={sortedCountries}
        value={selectedCountries}
        getOptionLabel={getOptionLabel}
        onChange={handleSelectionChange}
        disabled={disabled}
        className={className}
        limitTags={limitTags}
      />
      {allSelectedFieldId && <ErrorHelperTextFormik id={allSelectedFieldId} />}
      <ErrorHelperTextFormik id={id} />
    </>
  );
};

export default CountryMultiSelectFormik;
