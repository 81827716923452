import React, { useState } from 'react';
import axios from 'axios';

import { SortableTable } from '~/components/core';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Switch from '~/components/core/Atomic/Switch';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { CheckIcon, PencilIcon } from '~/components/icons';
import UpsertDocumentTypeDialog from '~/components/SystemConfiguration/DocumentTypes/UpsertDocumentTypeDialog';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import type { DocumentType } from '~/components/types/document-type-types';
import { reportAxiosError } from '~/Utils';
import { getLobDescription } from '~/Utils/lobUtils';

interface DocumentTypesTableProps {
  documentTypesDict: Record<string, DocumentType>;
  reloadDocumentTypes: () => void;
  searchTerm: string;
  showOnlyCustomTypes: boolean;
}

const DocumentTypesTable: React.FC<DocumentTypesTableProps> = ({
  documentTypesDict,
  reloadDocumentTypes,
  searchTerm,
  showOnlyCustomTypes,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { lobConfigurationsDict } = useLobConfiguration();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();

  const [documentTypeToEdit, setDocumentTypeToEdit] = useState<DocumentType | null>(null);

  const filteredDocumentTypes = Object.values(documentTypesDict)
    .filter((row: DocumentType) => row.display_name.includes(searchTerm) || row?.key?.includes(searchTerm))
    .filter((row: DocumentType) => !showOnlyCustomTypes || !row?.type || row.type === 'custom');

  const handleEditDocumentType = (documentType: DocumentType) => {
    setDocumentTypeToEdit(documentType);
  };

  const handleClose = () => {
    setDocumentTypeToEdit(null);
  };

  const handleEnableDocumentType = async (documentTypeId: number, newStatus: boolean) => {
    try {
      await axios.patch(
        `/api/v1/document_types/organizations/${organization.id}/document_types/${documentTypeId}/is_enabled`,
        {
          is_enabled: newStatus,
        }
      );
      await reloadDocumentTypes();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const getActionCell = (documentType: DocumentType) =>
    documentType.type === 'mandatory' ? null : (
      <IconButton onClick={() => handleEditDocumentType(documentType)}>
        <PencilIcon />
      </IconButton>
    );

  const handleUpdateDocumentType = async (values: Record<string, unknown>) => {
    try {
      if (!documentTypeToEdit) {
        return;
      }

      await axios.put(
        `/api/v1/document_types/organizations/${organization.id}/document_types/${documentTypeToEdit.id}`,
        values
      );
      await reloadDocumentTypes();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const columns = [
    { id: 'display_name', label: 'Name' },
    { id: 'key', label: 'Key' },
    {
      id: 'lobs',
      label: 'Lines of Business',
      specialCell: ({ is_all_lobs, lobs = [] }: { is_all_lobs: boolean; lobs: string[] }) =>
        is_all_lobs ? (
          'All'
        ) : (
          <span>{lobs?.map((lob) => getLobDescription(lob, lobConfigurationsDict)).join(', ')}</span>
        ),
    },
    {
      id: 'type',
      label: 'Is Custom',
      specialCell: ({ type }: { type: string }) => (type === 'custom' ? <CheckIcon className="fill-slate-700" /> : ''),
    },
    {
      id: 'is_enabled',
      disableSort: true,
      label: 'Enable',
      specialCell: (documentType: DocumentType) => {
        return (
          <Switch
            checked={documentType.is_enabled}
            onChange={async (_, newStatus) => {
              await handleEnableDocumentType(documentType.id, newStatus);
            }}
            disabled={false}
          />
        );
      },
    },
    {
      id: 'actions',
      label: 'Actions',
      specialCell: getActionCell,
    },
  ];

  return (
    <>
      <SortableTable columns={columns} rows={filteredDocumentTypes} />
      {documentTypeToEdit ? (
        <UpsertDocumentTypeDialog
          documentType={documentTypeToEdit}
          onSubmit={handleUpdateDocumentType}
          onClose={handleClose}
          documentTypesDict={documentTypesDict}
        />
      ) : null}
    </>
  );
};

export default DocumentTypesTable;
