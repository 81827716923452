import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import Checkbox from '~/components/core/Atomic/Checkboxes/Checkbox';
import Chip from '~/components/core/Atomic/Chip/Chip';
import TextField from '~/components/core/Molecules/Fields/TextField';

export interface MultiSelectAutoCompleteProps<T> {
  id: string;
  label?: string;
  options: T[];
  value?: T[] | undefined;
  getOptionLabel: (option: T) => string;
  onChange?: (value?: T[] | null) => void;
  disabled?: boolean;
  className?: string;
  limitTags?: number;
}

const MultiSelectAutoComplete = <T,>({
  id,
  label,
  options,
  value,
  getOptionLabel,
  onChange,
  disabled,
  className,
  limitTags,
}: MultiSelectAutoCompleteProps<T>): JSX.Element => {
  const handleChange = (event: React.SyntheticEvent, newValue: T[]): void => {
    if (onChange) {
      onChange(newValue);
    }
  };
  return (
    <Autocomplete
      className={className}
      onChange={handleChange}
      size="small"
      value={value}
      multiple
      limitTags={limitTags}
      id={id}
      disabled={disabled}
      options={options}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      renderOption={(props, option, { selected }) => {
        const { ...optionProps } = props;
        return (
          <li {...optionProps}>
            <Checkbox checked={selected} />
            {getOptionLabel(option)}
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} label={label} variant="standard" />}
      renderTags={(tags, getTagProps) =>
        tags.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return <Chip key={key} label={getOptionLabel(option)} size="small" {...tagProps} />;
        })
      }
    />
  );
};

export default MultiSelectAutoComplete;
