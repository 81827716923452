const COMMUNICATION_TEMPLATE_ACTION = {
  SENDER_TYPE: {
    CLAIM_OWNER: 'Claim owner',
    EXPOSURE_OWNER: 'Exposure owner',
    CLAIM_OWNER_SUPERVISOR: 'Claim owner supervisor',
    EXPOSURE_OWNER_SUPERVISOR: 'Exposure owner supervisor',
  },
  RECIPIENT: {
    INSURED: 'Insured',
    CLAIMANT: 'Claimant',
    PREFERRED_CONTACT: 'Preferred contact',
    ATTORNEY: 'Attorney',
  },
  CHANNEL: {
    EMAIL: 'Email',
  },
  VALIDATION_FAILURE_REASONS: {
    MISSING_RECIPIENT_CONTACT: 'The Email Recipient contact is missing',
    RECIPIENT_CONTACT_EMAILING_NOT_ALLOWED: 'The Recipient contact does not allow being emailed',
    RECIPIENT_CONTACT_SMSING_NOT_ALLOWED: 'The Recipient contact does not allow being sent sms messages',
    NO_VALID_SMS_PHONE: 'The Recipient contact does not have a phone that can receive sms messages',
    MISSING_SENDER: 'The Email sender is missing',
    MISSING_TEMPLATE: "The template doesn't exists",
    INVALID_TEMPLATE_TYPE: 'Invalid template type ',
    DISABLED_TEMPLATE: 'The template is disabled',
    TEMPLATE_FAILED_TOKEN_DISPLAY_NAMES: 'Some of the template tokens are unfulfilled',
    GENERAL_EXPENSES_PAYMENT:
      "General Expenses payment was made; note that this doesn't send an automatic communication",
  },
} as const;

export default COMMUNICATION_TEMPLATE_ACTION;
