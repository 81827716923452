import React from 'react';
import axios from 'axios';
import { startCase } from 'lodash';

import Chip from '~/components/core/Atomic/Chip/Chip';
import Switch from '~/components/core/Atomic/Switch';
import FsTooltip from '~/components/core/FsWrappers/FsTooltip/FsTooltip';
import OverflowArrayTextDisplayWithTooltip from '~/components/core/OverflowArrayTextWithTooltip/OverflowArrayTextDisplayWithTooltip';
import SortableTable from '~/components/core/Tables/SortableTable/index';
import type { GenericTemplatesRoutes, PaginationProps, TemplateMetadata } from '~/components/GenericTemplates/types';
import { TEMPLATES_TYPES } from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import { GENERIC_TEMPLATES_ROUTES } from '~/components/GenericTemplates/utils/routes';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { serverDateTimeToLocal } from '~/DateTimeUtils';
import { CONFIGURATION_FEATURES_NAMES, COUNTRY_TO_STATE_MAP } from '~/Types';
import { containsOneCountryWithStates, isFeatureEnabled, isOrganizationWithStates, reportAxiosError } from '~/Utils';
import { getLobDescription } from '~/Utils/lobUtils';

interface GenericTemplatesTablePropsType {
  templates: TemplateMetadata[];
  templatesCount: number;
  onSelectTemplate: (template_id: number) => void;
  onUpdatePaginationProps: (paginationProps: PaginationProps) => void;
  paginationProps: PaginationProps;
  shouldDisplayType: boolean;
  selectedTemplateId: number;
  reloadTable: () => Promise<void>;
  templateRoutesDict?: GenericTemplatesRoutes;
}

const GenericTemplatesTable: React.FC<GenericTemplatesTablePropsType> = ({
  templates,
  templatesCount,
  onSelectTemplate,
  onUpdatePaginationProps,
  paginationProps,
  shouldDisplayType,
  selectedTemplateId,
  reloadTable,
  templateRoutesDict = GENERIC_TEMPLATES_ROUTES,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const { lobConfigurationsDict } = useLobConfiguration();
  const multiCountryOrganization = isFeatureEnabled(
    organization,
    CONFIGURATION_FEATURES_NAMES.MULTI_COUNTRY_ORGANIZATION
  );

  const handleEnableTemplate = async (templateId: number, newStatus: boolean) => {
    try {
      await axios.patch(templateRoutesDict.UPDATE_TEMPLATE_IS_ENABLED(organization.id, templateId), {
        is_enabled: newStatus,
      });
      await reloadTable();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const columnData = [
    { id: 'template_name', label: 'Name' },
    {
      id: 'sub_organization_ids',
      disableSort: true,
      label: 'Sub-Organizations',
      isHidden: !organization.sub_organizations_enabled,
      specialCell: (template: TemplateMetadata) => (
        <>
          {template.sub_organization_ids && template.sub_organization_ids.length > 0
            ? organization.sub_organizations
                .filter((subOrg: { id: number }) => template.sub_organization_ids.includes(subOrg.id))
                .map((subOrg: { name: string }) => subOrg.name)
                .join(', ')
            : 'All'}
        </>
      ),
    },
    {
      id: 'last_update_datetime',
      label: 'Last Updated',
      specialCell: (template: TemplateMetadata) => serverDateTimeToLocal(template.last_update_datetime),
    },
    {
      id: 'countries',
      disableSort: true,
      label: 'Countries',
      isHidden: !multiCountryOrganization,
      width: '100px',
      specialCell: (template: TemplateMetadata) => {
        return (
          <>
            {template?.is_all_countries ? (
              'All'
            ) : template?.countries && template.countries.length > 0 ? (
              <OverflowArrayTextDisplayWithTooltip
                value={template.countries}
                renderItem={(label) => (
                  <Chip
                    key={label}
                    size="small"
                    className="mx-2"
                    label={<OverflowTextWithToolTip maxWidth="220px">{label}</OverflowTextWithToolTip>}
                  />
                )}
              />
            ) : null}
          </>
        );
      },
    },
    {
      id: 'states',
      disableSort: true,
      label: 'States',
      isHidden: !(isOrganizationWithStates(organization) || multiCountryOrganization),
      width: '100px',
      specialCell: (template: TemplateMetadata) => {
        const isStatesExist = template?.states && template.states.length > 0;
        const isEmptyStringAllOption = template?.states && template.states.length === 1 && template.states[0] === '';
        const statesWithoutEmpty =
          template.countries &&
          containsOneCountryWithStates(template.countries) &&
          Object.keys(COUNTRY_TO_STATE_MAP).includes(template.countries[0]) &&
          Object.keys(COUNTRY_TO_STATE_MAP[template.countries[0] as keyof typeof COUNTRY_TO_STATE_MAP]).filter(
            (state) => !!state
          );
        const isAllOptions =
          statesWithoutEmpty && template?.states && template.states.length === statesWithoutEmpty.length;

        return (
          <div>
            {isStatesExist && !isEmptyStringAllOption && !isAllOptions ? (
              <OverflowArrayTextDisplayWithTooltip
                value={template.states}
                renderItem={(label) => (
                  <Chip
                    key={label}
                    size="small"
                    className="mx-2"
                    label={<OverflowTextWithToolTip maxWidth="220px">{label}</OverflowTextWithToolTip>}
                  />
                )}
              />
            ) : !multiCountryOrganization || containsOneCountryWithStates(template.countries) ? (
              'All'
            ) : null}
          </div>
        );
      },
    },
    {
      id: 'lobs',
      disableSort: true,
      label: 'Line of Business',
      specialCell: (template: TemplateMetadata) => (
        <>
          {template.lobs && template.lobs.length > 0
            ? template.lobs.map((lob) => (
                <Chip label={getLobDescription(lob, lobConfigurationsDict)} size="small" key={lob} className="mx-2" />
              ))
            : 'All'}
        </>
      ),
    },
    {
      id: 'coverages',
      label: 'Coverages',
      disableSort: true,
      leftPaddingOnly: true,
      specialCell: (template: TemplateMetadata) => {
        return (
          <>
            {!template?.is_all_coverages &&
            !template?.is_claim_level &&
            template.coverage_keys &&
            template.coverage_keys.length > 0 ? (
              <OverflowArrayTextDisplayWithTooltip
                value={template.coverage_keys}
                renderItem={(label) => (
                  <Chip
                    key={label}
                    size="small"
                    className="mx-2"
                    label={<OverflowTextWithToolTip maxWidth="220px">{startCase(label)}</OverflowTextWithToolTip>}
                  />
                )}
              />
            ) : (
              'All'
            )}
          </>
        );
      },
    },
    {
      id: 'connected_automatic_rule_actions_display_names',
      disableSort: true,
      label: 'Connected Automation Rules',
      isHidden: !isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.AUTOMATIC_COMMUNICATIONS_RULES),
      specialCell: (template: TemplateMetadata) => (
        <OverflowArrayTextDisplayWithTooltip value={template.connected_automatic_rule_actions_display_names} />
      ),
    },
    {
      id: 'template_type',
      disableSort: true,
      label: 'Type',
      specialCell: (template: TemplateMetadata) => TEMPLATES_TYPES[template.template_type]?.display_name,
      isHidden: !shouldDisplayType,
    },
    {
      id: 'is_enabled',
      disableSort: true,
      label: 'Enable',
      specialCell: (template: TemplateMetadata) => {
        const disabled = !!(
          template?.connected_automatic_rule_actions_display_names &&
          template.connected_automatic_rule_actions_display_names.length > 0
        );
        return (
          <FsTooltip title={disabled ? 'Template cannot be disabled when connected to automatic rule' : ''}>
            <Switch
              checked={template.is_enabled}
              disabled={disabled}
              onChange={async (_, newStatus) => {
                await handleEnableTemplate(template.id, newStatus);
              }}
            />
          </FsTooltip>
        );
      },
    },
  ];

  const handleRowClick = (template_id: number) => {
    onSelectTemplate(template_id);
  };

  return (
    <SortableTable
      rows={templates}
      columns={columnData.filter((column) => !column.isHidden)}
      defaultOrderColumn={columnData.findIndex((column) => column.id === 'template_name')}
      order={paginationProps.order_by}
      onRowClick={handleRowClick}
      selectedRowsIds={[selectedTemplateId]}
      stickyHeader
      paginationProps={{
        page: paginationProps.page,
        rowsPerPage: paginationProps.limit,
        onChangePage: (_: unknown, newPage: number) => {
          const newPaginationProps = {
            ...paginationProps,
            page: newPage,
          };

          onUpdatePaginationProps(newPaginationProps);
        },
        count: templatesCount,
        onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => {
          const newPaginationProps = {
            ...paginationProps,
            page: 0,
            limit: Number(event.target.value),
          };

          onUpdatePaginationProps(newPaginationProps);
        },
        rowsPerPageOptions: [10, 20, 30],
      }}
      onSortByColumn={(_: () => void, { id, order }: { id: string; order: string }) => {
        const newPaginationProps = {
          ...paginationProps,
          page: 0,
          sort_by: id,
          order_by: order,
        };

        onUpdatePaginationProps(newPaginationProps);
      }}
    />
  );
};

export default GenericTemplatesTable;
