import React, { useState } from 'react';
import axios from 'axios';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import colors from '~/assets/colors.module.scss';
import Button from '~/components/core/Atomic/Buttons/Button';
import { PlusIcon } from '~/components/icons';
import UpsertDocumentTypeDialog from '~/components/SystemConfiguration/DocumentTypes/UpsertDocumentTypeDialog';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import type { DocumentType } from '~/components/types/document-type-types';
import { reportAxiosError } from '~/Utils';

interface AddDocumentTypeButtonProps {
  documentTypesDict: Record<string, DocumentType>;
  reloadDocumentTypes: () => void;
}

const AddDocumentTypeButton: React.FC<AddDocumentTypeButtonProps> = ({ documentTypesDict, reloadDocumentTypes }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();

  const [isAddDocumentTypeDialogIsOpen, setIsAddDocumentTypeDialogIsOpen] = useState(false);

  const handleAddDocumentType = async (values: Record<string, unknown>) => {
    try {
      await axios.post(`/api/v1/document_types/organizations/${organization.id}/document_types`, values);
      await reloadDocumentTypes();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  return (
    <>
      <Button
        className="justify-self-end text-teal-700"
        onClick={() => setIsAddDocumentTypeDialogIsOpen(true)}
        startIcon={<PlusIcon iconColor={colors.buttonLink} size={14} />}
      >
        Add Document Type
      </Button>
      {isAddDocumentTypeDialogIsOpen ? (
        <UpsertDocumentTypeDialog
          documentTypesDict={documentTypesDict}
          onSubmit={handleAddDocumentType}
          onClose={() => setIsAddDocumentTypeDialogIsOpen(false)}
        />
      ) : null}
    </>
  );
};

export default AddDocumentTypeButton;
