import React, { useState } from 'react';
import axios from 'axios';

import CardDialog from '~/components/CardDialog';
import LoadingSwitch from '~/components/core/Loading/LoadingSwitch';
import type { ConfigurableOptionProps } from '~/components/core/Molecules/ConfigurableOption';
import ConfigurableOption from '~/components/core/Molecules/ConfigurableOption';
import useNotificationsPreferences from '~/components/hooks/useNotificationsPreferences';
import NotificationsSortOrderEditDialog from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationSortOrderEditDialog';
import NotificationsPerPageEditDialog from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsPerPageEditDialog';
import type { NotificationsPreferences } from '~/components/SystemConfiguration/NotificationsConfiguration/types';
import { reportAxiosError } from '~/Utils';

import Heading from '../../core/TextComponents/Heading';

const NotificationsPreferencesScreen: React.FC = () => {
  const { configuration, isLoading, isError, reloadData, notificationsPreferencesRoute } =
    useNotificationsPreferences(true);

  const [isUpdatingConfiguration, setIsUpdatingConfiguration] = useState(false);

  const handleUpdateConfiguration = async (values: Partial<NotificationsPreferences>, throwOnError = true) => {
    try {
      setIsUpdatingConfiguration(true);
      await axios.patch(notificationsPreferencesRoute, values);

      await reloadData();
    } catch (error) {
      await reportAxiosError(error);
      if (throwOnError) {
        throw error;
      }
    } finally {
      setIsUpdatingConfiguration(false);
    }
  };

  const configurationOptions: ConfigurableOptionProps<NotificationsPreferences>[] = [
    {
      isEnabled: configuration?.is_default_notifications_per_page_enabled,
      onChangeToggle: async (isEnabled: boolean) =>
        await handleUpdateConfiguration({ is_default_notifications_per_page_enabled: isEnabled }, false),
      configurationView: <Heading variant={Heading.TYPES.H3}>Set default notifications per page</Heading>,
      EditConfigurationDialogComponent: NotificationsPerPageEditDialog,
      configuration,
      configurationKey: 'default_notifications_per_page',
      onSubmitDialog: handleUpdateConfiguration,
    },
    {
      isEnabled: configuration?.is_default_sort_order_enabled,
      onChangeToggle: async (isEnabled: boolean) =>
        await handleUpdateConfiguration({ is_default_sort_order_enabled: isEnabled }, false),
      configurationView: <Heading variant={Heading.TYPES.H3}>Set default sort order</Heading>,
      EditConfigurationDialogComponent: NotificationsSortOrderEditDialog,
      configuration,
      configurationKey: 'default_sort_order',
      onSubmitDialog: handleUpdateConfiguration,
    },
    {
      isEnabled: configuration?.is_default_view_all_in_claims_page,
      onChangeToggle: async (isEnabled: boolean) =>
        await handleUpdateConfiguration({ is_default_view_all_in_claims_page: isEnabled }, false),
      configurationView: (
        <Heading variant={Heading.TYPES.H3}>
          Present all adjusters notification in the claim page notifications filter
        </Heading>
      ),
    },
  ];

  return (
    <div className="bg-slate-100 pt-32">
      <CardDialog width="md" noCardTitle>
        <LoadingSwitch isError={isError} isLoading={isLoading}>
          {configurationOptions.map(
            (
              {
                isEnabled,
                onChangeToggle,
                configurationView,
                configuration,
                EditConfigurationDialogComponent,
                onSubmitDialog,
                configurationKey,
              },
              idx
            ) => (
              <ConfigurableOption<NotificationsPreferences>
                key={idx}
                isEnabled={isEnabled}
                onChangeToggle={onChangeToggle}
                configurationView={configurationView}
                configuration={configuration}
                EditConfigurationDialogComponent={EditConfigurationDialogComponent}
                showOnly={isUpdatingConfiguration}
                onSubmitDialog={onSubmitDialog}
                configurationKey={configurationKey}
              />
            )
          )}
        </LoadingSwitch>
      </CardDialog>
    </div>
  );
};

export default NotificationsPreferencesScreen;
