import type { ReactElement } from 'react';
import React from 'react';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { noop } from 'lodash';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import MenuItem from '~/components/core/Atomic/MenuItem';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import type { EditConfigurationDialogComponentProps } from '~/components/core/Molecules/ConfigurableOption';
import type { NotificationsPreferences } from '~/components/SystemConfiguration/NotificationsConfiguration/types';
import TextFieldFormik from '~/components/TextFieldFormik';
import { reportErrorInProductionOrThrow } from '~/Utils';

const sortOrderOptions = {
  desc: 'Descending (Newest notifications will appear on the top of the table)',
  asc: 'Ascending (Newest notifications will appear on the bottom of the table)',
};

const NotificationsSortOrderEditDialog = ({
  configuration,
  onCancel = noop,
  onSubmit,
}: EditConfigurationDialogComponentProps<NotificationsPreferences>): ReactElement => {
  const classes = useStyles();

  const handleSubmit = async (
    values: Partial<NotificationsPreferences>,
    formikHelpers: FormikHelpers<Partial<NotificationsPreferences>>
  ) => {
    try {
      if (!onSubmit) {
        reportErrorInProductionOrThrow('No onSubmit function for NotificationsSortOrderEditDialog');
        return;
      }
      await onSubmit({
        default_sort_order: values.default_sort_order,
        is_default_sort_order_enabled: true,
      });
      onCancel();
    } catch {
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={{ default_sort_order: configuration?.default_sort_order || 'desc' }} onSubmit={handleSubmit}>
      {({ isSubmitting, handleSubmit }) => {
        return (
          <CardDialog
            title="Default sort order"
            isDialog
            maxWidth="sm"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
            footerActions={
              <DialogFooterActions
                primaryLabel="Save"
                onClickPrimary={handleSubmit}
                onClickSecondary={onCancel}
                disabled={isSubmitting}
              />
            }
          >
            <TextFieldFormik
              id="default_sort_order"
              select
              label="Default sort order"
              disabled={isSubmitting}
              className={classes.textField}
              fullWidth
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*@ts-ignore*/}
              {Object.keys(sortOrderOptions).map((key: 'desc' | 'asc') => (
                <MenuItem key={key} value={key}>
                  {sortOrderOptions[key]}
                </MenuItem>
              ))}
            </TextFieldFormik>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

export default NotificationsSortOrderEditDialog;
